import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconNegociation.module.css';
const SIZE_SMALL = 'small';

const IconNegociation = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);


  return (
    <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3242 19.5V30H17.3242V19.5H21.8242L15.8242 13.5L9.82422 19.5H14.3242ZM12.8242 10.5H8.32422V0H5.32422V10.5H0.824219L6.82422 16.5L12.8242 10.5Z" fill="black" />
    </svg>

  );
};

const { string } = PropTypes;

IconNegociation.defaultProps = {
  className: null,
  rootClassName: null,
};

IconNegociation.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconNegociation;
