/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - listingTypeConfig (optional):  Relationship configuration against listing types.
 *   - limitToListingTypeIds:         Indicator whether this listing field is relevant to a limited set of listing types.
 *   - listingTypeIds:                An array of listing types, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToListingTypeIds is true.
 * - categoryConfig (optional):     Relationship configuration against categories.
 *   - limitToCategoryIds:            Indicator whether this listing field is relevant to a limited set of categories.
 *   - categoryIds:                   An array of categories, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToCategoryIds is true.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  // {
  //   "scope": "public",
  //   "label": "Gears",
  //   "key": "gears",
  //   "schemaType": "long",
  //   "numberConfig": {
  //     "minimum": 1,
  //     "maximum": 24
  //   },
  //   "filterConfig": {
  //     "indexForSearch": true,
  //     "group": "primary",
  //     "label": "Gears"
  //   }
  // }
  // {
  //   key: 'bikeType',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'city-bikes', label: 'City bikes' },
  //     { option: 'electric-bikes', label: 'Electric bikes' },
  //     { option: 'mountain-bikes', label: 'Mountain bikes' },
  //     { option: 'childrens-bikes', label: "Children's bikes" },
  //   ],
  //   categoryConfig: {
  //     limitToCategoryIds: true,
  //     categoryIds: ['cats'],
  //   },
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
  //     label: 'Bike type',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Bike type',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Bike type',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a bike type.',
  //   },
  // },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },
  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   schemaType: 'text',
  //   listingTypeConfig: {
  //     limitToListingTypeIds: true,
  //     listingTypeIds: ['product-selling'],
  //   },
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   schemaType: 'text',
  //   listingTypeConfig: {
  //     limitToListingTypeIds: true,
  //     listingTypeIds: ['daily-booking'],
  //   },
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */


export const compensationPercentage = Array.from({ length: 30 }, (_, i) => {
  const value = (0.1 * (i + 1)).toFixed(1);
  return {
    option: parseFloat(value),
    label: parseFloat(value)
  };
});

export const agencyAgreementLength = [
  { option: '7-days', label: '7 days' },
  { option: '30-days', label: '30 days' },
  { option: '60-days', label: '60 days' },
  { option: '90-days', label: '90 days' },
]

export const showingAbility = [
  { option: 'anytime', label: 'Anytime' },
  { option: 'during-the-week', label: 'During the week' },
  { option: 'mornings', label: 'Mornings' },
  { option: 'evenings', label: 'Evenings' },
]
export const wishlist = [
  {
    option: 'Help you create a wishlist of must-have features and desired attributes in a home.',
    label: 'Help you create a wishlist of must-have features and desired attributes in a home.'
  },
]

export const initialConsultation = [
  {
    option: 'Conduct a through consultation to understand your needs, preferences, budget and timeline.',
    label: 'Conduct a through consultation to understand your needs, preferences, budget and timeline.'
  },
]

export const marketAnalysis = [
  {
    option: 'Provide detailed information about current market conditions, including trends and pricing in your desired areas.',
    label: 'Provide detailed information about current market conditions, including trends and pricing in your desired areas.'
  },
]

export const insight = [
  {
    option: 'Offer insights into different neighborhoods, including schools, amenities, crime rates, and future development plans.',
    label: 'Offer insights into different neighborhoods, including schools, amenities, crime rates, and future development plans.'
  },
]

export const customSearch = [
  {
    option: 'Offer insights into different neighborhoods, including schools, amenities, crime rates, and future development plans.',
    label: 'Offer insights into different neighborhoods, including schools, amenities, crime rates, and future development plans.'
  },
]

export const alert = [
  {
    option: 'Set up alerts for new listings and price changes on properties of interest.',
    label: 'Set up alerts for new listings and price changes on properties of interest.'
  },
]

export const evaluation = [
  {
    option: 'Help you evaluate each property, pointing out both positive aspects and potential issues.',
    label: 'Help you evaluate each property, pointing out both positive aspects and potential issues.'
  },
]

export const customizedSearches = [
  {
    option: 'Use local MLS and other resources to find properties that match your criteria.',
    label: 'Use local MLS and other resources to find properties that match your criteria.'
  },
]
export const alerts = [
  {
    option: 'Set up alerts for new listings and price changes on properties of interest.',
    label: 'Set up alerts for new listings and price changes on properties of interest.'
  },
]

export const offerStrategy = [
  {
    option: 'Advise on the best strategy for making an offer, including the price, contingencies, and other terms.',
    label: 'Advise on the best strategy for making an offer, including the price, contingencies, and other terms.'
  },
]
export const negotiation = [
  {
    option: 'Negotiate on your behalf to get the best possible terms and conditions, including price, repairs, and closing costs.',
    label: 'Negotiate on your behalf to get the best possible terms and conditions, including price, repairs, and closing costs.'
  },
]
export const inspection = [
  {
    option: 'Recommend and coordinate property inspections and evaluations (e.g., home inspections, termite inspections, etc).',
    label: 'Recommend and coordinate property inspections and evaluations (e.g., home inspections, termite inspections, etc).'
  },
]
export const disclosures = [
  {
    option: 'Ensure all necessary disclosures are provided and reviewed.',
    label: 'Ensure all necessary disclosures are provided and reviewed.'
  },
]
export const lenderRecommendation = [
  {
    option: 'Provide recommendations for reputable lenders and assist with obtaining mortgage pre-approval.',
    label: 'Provide recommendations for reputable lenders and assist with obtaining mortgage pre-approval.'
  },
]
export const loanProcess = [
  {
    option: 'Guide you through the mortgage application process and help resolve any issues that arise.',
    label: 'Guide you through the mortgage application process and help resolve any issues that arise.'
  },
]
export const contractManagement = [
  {
    option: 'Handle all paperwork and ensure all contractual obligations are met within specified timelines.',
    label: 'Handle all paperwork and ensure all contractual obligations are met within specified timelines.'
  },
]
export const contingencies = [
  {
    option: ' Manage and track all contingencies, ensuring they are resolved satisfactorily.',
    label: ' Manage and track all contingencies, ensuring they are resolved satisfactorily.'
  },
]
export const communication = [
  {
    option: 'Maintain open and regular communication, keeping you updated on the progress of the transaction.',
    label: 'Maintain open and regular communication, keeping you updated on the progress of the transaction. '
  },
]
export const finalWalkthrough = [
  {
    option: 'Arrange and accompany you on the final walkthrough to ensure the property is in the agreed-upon condition.',
    label: 'Arrange and accompany you on the final walkthrough to ensure the property is in the agreed-upon condition.'
  },
]
export const closingCoordination = [
  {
    option: "Coordinate with the closing agent, lender, and seller's agent to ensure a smooth closing process.",
    label: "Coordinate with the closing agent, lender, and seller's agent to ensure a smooth closing process."
  },
]
export const documentReview = [
  {
    option: 'Assist in reviewing closing documents and ensure all terms and conditions are met before signing.',
    label: 'Assist in reviewing closing documents and ensure all terms and conditions are met before signing.'
  },
]
export const sellerMarketAnalysis = [
  {
    option: 'Conduct a Comparative Market Analysis (CMA) to determine the best listing price based on recent sales,market trends, and the condition of your property.',
    label: 'Conduct a Comparative Market Analysis (CMA) to determine the best listing price based on recent sales,market trends, and the condition of your property.'
  },
]
export const sellerPricingStrategy = [
  {
    option: 'Advise on a pricing strategy that balances attracting buyers with maximizing your return.',
    label: 'Advise on a pricing strategy that balances attracting buyers with maximizing your return.'
  },
]
export const sellerStagingAdvice = [
  {
    option: 'Provide guidance on staging the home to enhance its appeal, which may include decluttering,rearranging furniture, or making minor repairs.',
    label: 'Provide guidance on staging the home to enhance its appeal, which may include decluttering,rearranging furniture, or making minor repairs.'
  },
]
export const repairsAndImprovements = [
  {
    option: 'Recommend and sometimes coordinate necessary repairs or improvements to increase the property’s value and appeal.',
    label: 'Recommend and sometimes coordinate necessary repairs or improvements to increase the property’s value and appeal.'
  },
]
export const professionalPhotography = [
  {
    option: 'Arrange for high-quality photos and possibly videos or virtual tours of the property.',
    label: 'Arrange for high-quality photos and possibly videos or virtual tours of the property.'
  },
]
export const onlineListing = [
  {
    option: 'Ensure the property is listed on major real estate websites, including MLS , Zillow, Realtor.com, and social media platforms.',
    label: 'Ensure the property is listed on major real estate websites, including MLS , Zillow, Realtor.com, and social media platforms.'
  },
]

export const traditionalMarketing = [
  {
    option:
      'Use traditional marketing methods such as open houses, yard signs, and online advertisements.',
    label:
      'Use traditional marketing methods such as open houses, yard signs, and online advertisements.',
  },
];

export const scheduling = [
  {
    option:
      'Manage and coordinate showings and open houses, making it convenient for potential buyers while respecting your schedule.',
    label:
      'Manage and coordinate showings and open houses, making it convenient for both the seller and potential buyers while respecting your schedule.',
  },
];
export const feedbacks = [
  {
    option:
      'Gather feedback from showings and open houses to refine the marketing strategy if necessary.',
    label:
      'Gather feedback from showings and open houses to refine the marketing strategy if necessary.',
  },
];

export const regularUpdates = [
  {
    option:
      'Provide regular updates on market conditions, feedback from showings, and any offers received.',
    label:
      'Provide regular updates on market conditions, feedback from showings, and any offers received.',
  },
];
export const accessibility = [
  {
    option: 'Be readily available to answer questions, provide updates, and address any concerns.',
    label: 'Be readily available to answer questions, provide updates, and address any concerns.',
  },
];

export const offers = [
  {
    option:
      'Present all offers to you and provide a detailed analysis of each, highlighting their pros and cons.',
    label:
      'Present all offers to you and provide a detailed analysis of each, highlighting their pros and cons.',
  },
];
export const counterOffers = [
  {
    option:
      'Negotiate terms and conditions of the sale to ensure you get the best possible price and terms.',
    label:
      'Negotiate terms and conditions of the sale to ensure you get the best possible price and terms.',
  },
];

export const buyerContingencies = [
  {
    option:
      'Manage all contingencies, ensuring that both parties fulfill their obligations to move the transaction forward.',
    label:
      'Manage all contingencies, ensuring that both parties fulfill their obligations to move the transaction forward.',
  },
];
export const documentations = [
  {
    option:
      'Handle all necessary paperwork and ensure that everything is completed accurately and timely.',
    label:
      'Handle all necessary paperwork and ensure that everything is completed accurately and timely.',
  },
];
export const coordination = [
  {
    option:
      'Work with all parties involved in the transaction, including the buyer’s agent, lenders, appraisers, and closing agents.',
    label:
      'Negotiate terms and conditions of the sale to ensure you get the best possible price and terms.',
  },
];
export const sellerFinalWalkThrough = [
  {
    option: 'Coordinate the final walkthrough with the buyer.',
    label: 'Coordinate the final walkthrough with the buyer',
  },
];
export const closing = [
  {
    option:
      'Assist in preparing for the closing, ensuring that all documents are in order and all conditions are met.',
    label:
      'Assist in preparing for the closing, ensuring that all documents are in order and all conditions are met.',
  },
];
export const sellerFollowUp = [
  {
    option:
      'Provide support after the sale is completed such as assisting with moving logistics or resolving any post-closing issues.',
    label:
      'Provide support after the sale is completed such as assisting with moving logistics or resolving any post-closing issues.',
  },
];

// 1 for 10 USD, 3 for 20 USD and 5 for 30 USD

export const coinsForContact = [
  { credits: 1, price: 10 },
  { credits: 3, price: 20 },
  { credits: 5, price: 30 }
]

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

