import React from 'react';
// Import config and utils
import { useIntl } from '../../util/reactIntl';
import {
    SCHEMA_TYPE_ENUM,
    SCHEMA_TYPE_MULTI_ENUM,
    SCHEMA_TYPE_TEXT,
    SCHEMA_TYPE_LONG,
    SCHEMA_TYPE_BOOLEAN,
} from '../../util/types';
import { required, nonEmptyArray, validateInteger } from '../../util/validators';
// Import shared components
import { FieldCheckboxGroup, FieldSelect, FieldTextInput, FieldBoolean, FieldMultiSelect, FieldRadioButton } from '../../components';
// Import modules from this directory
import css from './CustomExtendedDataField.module.css';
const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));
const getLabel = fieldConfig => fieldConfig?.saveConfig?.label || fieldConfig?.label;
const CustomFieldEnum = props => {
    const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
    const { enumOptions = [], saveConfig } = fieldConfig || {};
    const { placeholderMessage, isRequired, requiredMessage } = saveConfig || {};
    const validateMaybe = isRequired
        ? { validate: required(requiredMessage || defaultRequiredMessage) }
        : {};
    const placeholder =
        placeholderMessage ||
        intl.formatMessage({ id: 'CustomExtendedDataField.placeholderSingleSelect' });
    const filterOptions = createFilterOptions(enumOptions);
    const label = getLabel(fieldConfig);
    return filterOptions.length > 0 ? (
        // <FieldSelect
        //   className={css.customField}
        //   name={name}
        //   id={formId ? `${formId}.${name}` : name}
        //   label={label}
        //   {...validateMaybe}
        // >
        //   <option disabled value="">
        //     {placeholder}
        //   </option>
        //   {filterOptions.map(optionConfig => {
        //     const key = optionConfig.key;
        //     return (
        //       <option key={key} value={key}>
        //         {optionConfig.label}
        //       </option>
        //     );
        //   })}
        // </FieldSelect>


        <div>
            <label className={css.fieldRadioLabel}>
                {label}
            </label>
            <div className={css.radioInput}>
                {filterOptions.map((st, i) => {
                    const { key, label } = st;

                    return (
                        <div className={css.colorBoxOut} key={`${label}_${i}`}>
                            <FieldRadioButton id={key} name={name} value={key} label={label} {...validateMaybe} />
                        </div>
                    );
                })}
            </div>
        </div>
    ) : null;
};
const CustomFieldMultiEnum = props => {
    const { name, fieldConfig, defaultRequiredMessage, formId, values } = props;
    const { enumOptions = [], saveConfig } = fieldConfig || {};
    const { isRequired, requiredMessage } = saveConfig || {};
    const label = getLabel(fieldConfig);
    const validateMaybe = isRequired
        ? { validate: nonEmptyArray(requiredMessage || defaultRequiredMessage) }
        : {};
    return <FieldCheckboxGroup
        className={css.customField}
        id={formId ? `${formId}.${name}` : name}
        name={name}
        label={label}
        options={createFilterOptions(enumOptions)}
        {...validateMaybe}
    />
};
const CustomFieldText = props => {
    const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
    const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
    const label = getLabel(fieldConfig);
    const validateMaybe = isRequired
        ? { validate: required(requiredMessage || defaultRequiredMessage) }
        : {};
    const placeholder =
        placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderText' });
    return (
        <FieldTextInput
            className={css.customField}
            id={formId ? `${formId}.${name}` : name}
            name={name}
            type="textarea"
            label={label}
            placeholder={placeholder}
            {...validateMaybe}
        />
    );
};
const CustomFieldLong = props => {
    const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
    const { minimum, maximum, saveConfig } = fieldConfig;
    const { placeholderMessage, isRequired, requiredMessage } = saveConfig || {};
    const label = getLabel(fieldConfig);
    const placeholder =
        placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderLong' });
    const numberTooSmallMessage = intl.formatMessage(
        { id: 'CustomExtendedDataField.numberTooSmall' },
        { min: minimum }
    );
    const numberTooBigMessage = intl.formatMessage(
        { id: 'CustomExtendedDataField.numberTooBig' },
        { max: maximum }
    );
    // Field with schema type 'long' will always be validated against min & max
    const validate = (value, min, max) => {
        const requiredMsg = requiredMessage || defaultRequiredMessage;
        return isRequired && value == null
            ? requiredMsg
            : validateInteger(value, max, min, numberTooSmallMessage, numberTooBigMessage);
    };
    return (
        <FieldTextInput
            className={css.customField}
            id={formId ? `${formId}.${name}` : name}
            name={name}
            type="number"
            step="1"
            parse={value => {
                const parsed = Number.parseInt(value, 10);
                return Number.isNaN(parsed) ? null : parsed;
            }}
            label={label}
            placeholder={"Price"}
            validate={value => validate(value, minimum, maximum)}
        />
    );
};
const CustomFieldBoolean = props => {
    const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
    const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
    const label = getLabel(fieldConfig);
    const validateMaybe = isRequired
        ? { validate: required(requiredMessage || defaultRequiredMessage) }
        : {};
    const placeholder =
        placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderBoolean' });
    return (
        <FieldBoolean
            className={css.customField}
            id={formId ? `${formId}.${name}` : name}
            name={name}
            label={label}
            placeholder={placeholder}
            {...validateMaybe}
        />
    );
};
/**
 * Return Final Form field for each configuration according to schema type.
 *
 * These custom extended data fields are for generating input fields from configuration defined
 * in marketplace-custom-config.js. Other panels in EditListingWizard might add more extended data
 * fields (e.g. shipping fee), but these are independently customizable.
 *
 * @param {Object} props should contain fieldConfig that defines schemaType, enumOptions?, and
 * saveConfig for the field.
 */

const CustomExtendedDataFieldForEdit = props => {
    const intl = useIntl();
    const { enumOptions = [], schemaType } = props?.fieldConfig || {};
    const renderFieldComponent = (FieldComponent, props) => <FieldComponent {...props} intl={intl} />;
    return schemaType === SCHEMA_TYPE_ENUM && enumOptions
        ? renderFieldComponent(CustomFieldEnum, props)
        : schemaType === SCHEMA_TYPE_MULTI_ENUM && enumOptions
            ? renderFieldComponent(CustomFieldMultiEnum, props)
            : schemaType === SCHEMA_TYPE_TEXT
                ? renderFieldComponent(CustomFieldText, props)
                : schemaType === SCHEMA_TYPE_LONG
                    ? renderFieldComponent(CustomFieldLong, props)
                    : schemaType === SCHEMA_TYPE_BOOLEAN
                        ? renderFieldComponent(CustomFieldBoolean, props)
                        : null;
};
export default CustomExtendedDataFieldForEdit;