import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import {
  Form,
  PrimaryButton,
  FieldTextInput,
  Modal,
  FieldRadioButton,
  Button,
  FieldLocationAutocompleteInput,
  LinkTabNavHorizontal,
  FieldCheckboxGroup,
  FieldMultiSelect,
  FieldCurrencyInput,
  FieldSelect,
  CustomExtendedDataField,
  FieldPhoneNumberInput,
} from '../../components';
import { FormattedMessage, injectIntl } from 'react-intl';
import appSettings from '../../config/settings';
import { useConfiguration } from '../../context/configurationContext';
import { compensationPercentage } from '../../config/configListing';
import css from './UpdateCompensation.module.css';

const UpdateCompensationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const { handleSubmit, values, formId, autoFocus, intl ,updateInProgress} = formRenderProps;
      

      const config = useConfiguration();
      const marketplaceCurrency = config.currency;

      const offer = [
        { option: 'fixed-amount', label: 'Fixed amount' },
        { option: 'percentage', label: 'Percentage' },
      ];
      return (
        <Form onSubmit={handleSubmit}>
          <div className={css.compensationModal}>
            <div className={css.compensationHeading}>
              <FormattedMessage id="UpdateCompensation.Heading" />
            </div>
            <div className={css.modalForm}>
              {offer.map((st, i) => {
                const type = st.userType;
                return (
                  <div
                    // className={css.colorBoxOut}
                    key={st.label + '_' + i}
                  >
                    <FieldRadioButton
                      id={st.option}
                      key={st.option}
                      name="compensationOffer"
                      value={st.option}
                      label={st.label}
                    />
                  </div>
                );
              })}
              {values && values.compensationOffer == 'percentage' && (
                <FieldSelect
                  className={css.selectCompensation}
                  id={formId ? `${formId}.compPercentage` : 'compPercentage'}
                  name="compPercentage"
                  label={intl.formatMessage({ id: 'SignupForm.compPercentageLabel' })}
                >
                  <option disabled value="">
                    {intl.formatMessage({
                      id: 'SignupForm.compPercentagePlaceholder',
                    })}
                  </option>
                  {compensationPercentage.map(st => {
                    return (
                      <option key={st.option} value={st.option}>
                        {st.label}
                      </option>
                    );
                  })}
                </FieldSelect>
              )}
              {values && values.compensationOffer == 'fixed-amount' && (
                <FieldCurrencyInput
                  className={css.selectCompensation}
                  id={formId ? `${formId}.compPrice` : 'compPrice'}
                  name="compPrice"
                  // className={css.zipCode}
                  autoFocus={autoFocus}
                  label={intl.formatMessage({ id: 'SignupForm.compPriceLabel' })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.compPricePlaceholder',
                  })}
                  currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
                />
              )}
              <div
              // className={css.actionButtons}
              >
                <Button 
                type="submit" 
                updateInProgress={updateInProgress}
                disabled={!(values.compPercentage || values.compPrice)}
                >
                  Update Anchor
                </Button>
              </div>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

UpdateCompensationFormComponent.defaultProps = {};

UpdateCompensationFormComponent.propTypes = {};

const UpdateCompensationForm = compose(injectIntl)(UpdateCompensationFormComponent);
UpdateCompensationForm.displayName = 'UpdateCompensationForm';

export default UpdateCompensationForm;
