import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, removePubKeys, simplifyPrices } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import { H3, Page, UserNav, NamedLink, LayoutSingleColumn, LayoutWrapperDashboardSideNav } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { types as sdkTypes } from '../../util/sdkLoader';

import { updateProfile, uploadImage } from './UpdateSignupDetailsPage.duck'
import css from './UpdateSignupDetailsPage.module.css'
import { withRouter } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import classNames from 'classnames';
import IconTooggleMenu from '../../components/IconTooggleMenu/IconTooggleMenu';
import IconCross from '../../components/IconCross/IconCross';
import UpdateCompensationForm from '../DasboardPage/UpdateCompensation';
import UpdateSignupDetailsPageFrom from './UpdateSignupDetailsPageFrom';
import { currentUserShowError } from '../../ducks/user.duck';
import { withViewport } from '../../util/uiHelpers';




const { UUID, LatLng, Money } = sdkTypes;
const getInitialValues = (currentUser) => {
    const initialValues = {};
    const { publicData = {} } = currentUser?.attributes?.profile || {};
    const priceKeys = ["compPrice", "maxPrice", "minPrice"];
    const directKeys = ["compPercentage", "zipCode", "location"];
    Object.keys(publicData).forEach((key) => {
        if (priceKeys.includes(key)) {
            const value = publicData[key];
            initialValues[key] = (value != null && !isNaN(value)) ? new Money(value * 100, "USD") : new Money(0, "USD");
        } else if (directKeys.includes(key)) {
            initialValues[key] = publicData[key];
        } else {
            initialValues[`pub_${key}`] = publicData[key];
        }
    });
    if (publicData && publicData.location && publicData.location.selectedPlace?.origin && publicData.location?.selectedPlace?.address) {
        const { address, origin } = publicData.location.selectedPlace;
        const { lat, lng } = origin;
        const geolocation = new LatLng(lat, lng);
        Object.assign(initialValues, {
            location:
            {
                search: address,
                selectedPlace: { address, origin: geolocation },
            }
        }
        )
    };
    return initialValues;
};

export const ProfileSettingsPageComponent = props => {
    const config = useConfiguration();
    const {
        currentUser,
        image,
        onImageUpload,
        onUpdateProfile,
        scrollingDisabled,
        updateInProgress,
        intl,
        viewport,
        history,
    } = props;
    const isMobileLayout = viewport?.width && viewport?.width < 768;
    const routeConfiguration = useRouteConfiguration();
    const routes = routeConfiguration;
    const { userFields, userTypes = [] } = config.user;
    const [show, setShow] = React.useState(false);
    const sidebarRef = useRef(null);
    const handleClickOutside = event => {
        if (event.target !== sidebarRef.current) {
            setShow(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    const handleSubmit = (values) => {
        const arrangedPublicData = removePubKeys(values)
        const arrangedPublicDatawithMoney = simplifyPrices(arrangedPublicData)
        const profileNeedToUpdateData = {
            publicData: { ...arrangedPublicDatawithMoney }

        };
        onUpdateProfile(profileNeedToUpdateData)
    };

    const user = ensureCurrentUser(currentUser);
    const email = user?.attributes?.email || ''
    const {
        firstName,
        lastName,
        bio,
        publicData,
    } = user?.attributes?.profile || {};
    const { userType, location, maxPrice, minPrice, ...rest } = publicData || {};
    const profileImageId = user?.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };
    const userTypeConfig = userTypes.find(config => config.userType === userType);
    const isDisplayNameIncluded = userTypeConfig?.defaultUserFields?.displayName !== false;
    // ProfileSettingsForm decides if it's allowed to show the input field.

    const initialValuesProfile = {
        firstName,
        lastName,
        bio,
        email,
        profileImage: user.profileImage,
        maxPrice: new Money(maxPrice * 100, "USD"),
        minPrice: new Money(minPrice * 100, "USD"),
        ...rest
    }

    if (publicData && publicData.location && publicData.location.selectedPlace?.origin && publicData.location?.selectedPlace?.address) {
        const { address, origin } = publicData.location.selectedPlace;
        const { lat, lng } = origin;
        const geolocation = new LatLng(lat, lng);
        Object.assign(initialValuesProfile, {
            location:
            {
                search: address,
                selectedPlace: { address, origin: geolocation },
            }
        }
        )
    };
    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });
    return (
        <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
            <TopbarContainer />

            <div className={css.editListingPageWrapper}>
                <div
                    ref={sidebarRef}
                    className={classNames(css.sidebar, show && isMobileLayout ? css.sidebarMobile : null)}
                >
                    <LayoutWrapperDashboardSideNav currentTab="UpdateSignupDetailsPage" />
                </div>
                <div className={css.mainContent}>
                    <div className={css.pageTitle}>
                        <FormattedMessage id="UpdateSignupDetails.Heading" />
                        <span onClick={() => setShow(!show)}>
                            {!show ? (
                                <IconTooggleMenu className={css.menuIcon} />
                            ) : (
                                <IconCross rootClassName={css.closeIcon} />
                            )}
                        </span>
                    </div>
                    {currentUser && currentUser.id
                        ? <UpdateSignupDetailsPageFrom
                            currentUser={currentUser}
                            updateInProgress={updateInProgress}
                            initialValues={getInitialValues(currentUser)}
                            // onSubmit={(values) => handleSubmit(values)}
                            onSubmit={(val) => handleSubmit(val)}
                            intl={intl}
                            userFields={userFields}
                            config={config}
                        />
                        : null}

                </div>
            </div>

            <FooterContainer />
        </Page>
    );
};

ProfileSettingsPageComponent.defaultProps = {
    currentUser: null,
    uploadImageError: null,
    updateProfileError: null,
    image: null,
    config: null,
};

ProfileSettingsPageComponent.propTypes = {
    currentUser: propTypes.currentUser,
    image: shape({
        id: string,
        imageId: propTypes.uuid,
        file: object,
        uploadedImage: propTypes.image,
    }),
    onImageUpload: func.isRequired,
    onUpdateProfile: func.isRequired,
    scrollingDisabled: bool.isRequired,
    updateInProgress: bool.isRequired,
    updateProfileError: propTypes.error,
    uploadImageError: propTypes.error,
    uploadInProgress: bool.isRequired,

    // from useConfiguration()
    config: object,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { currentUser } = state.user;
    const {
        image,
        uploadImageError,
        uploadInProgress,
        updateInProgress,
        updateProfileError,
    } = state.ProfileSettingsPage;
    return {
        currentUser,
        image,
        scrollingDisabled: isScrollingDisabled(state),
        updateInProgress,
        updateProfileError,
        uploadImageError,
        uploadInProgress,
    };
};

const mapDispatchToProps = dispatch => ({
    onImageUpload: data => dispatch(uploadImage(data)),
    onUpdateProfile: data => dispatch(updateProfile(data)),
});

const ProfileSettingsPage = compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    injectIntl,
    withViewport
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;

