import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconUpdate.module.css';
const SIZE_SMALL = 'small';

const IconUpdate = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);


  return (
    <svg fill="#000000" height="30px" width="30px" version="1.1"
      viewBox="0 0 24 24" enableBackground="new 0 0 24 24" >
      <g id="update">
        <g>
          <path d="M12,24c-4.1,0-7.8-2-10-5.4V24H0v-9h9v2H3.3c1.8,3.1,5,5,8.7,5c5.5,0,10-4.5,10-10h2C24,18.6,18.6,24,12,24z M2,12H0
			C0,5.4,5.4,0,12,0c4.1,0,7.8,2,10,5.4V0h2v9h-9V7h5.7c-1.8-3.1-5-5-8.7-5C6.5,2,2,6.5,2,12z" fill="#000000" />
        </g>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconUpdate.defaultProps = {
  className: null,
  rootClassName: null,
};

IconUpdate.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconUpdate;
