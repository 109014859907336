import {
    TX_TRANSITION_ACTOR_CUSTOMER as CUSTOMER,
    TX_TRANSITION_ACTOR_PROVIDER as PROVIDER,
    CONDITIONAL_RESOLVER_WILDCARD,
    ConditionalResolver,
} from '../../transactions/transaction';

/**
 * Get state data against product process for TransactionPage's UI.
 * I.e. info about showing action buttons, current state etc.
 *
 * @param {*} txInfo detials about transaction
 * @param {*} processInfo  details about process
 */
export const getStateDataForcreditPurchaseProcess = (txInfo, processInfo) => {
    const { transaction, transactionRole, nextTransitions } = txInfo;
    const isProviderBanned = transaction?.provider?.attributes?.banned;
    const isShippable = transaction?.attributes?.protectedData?.deliveryMethod === 'shipping';
    const _ = CONDITIONAL_RESOLVER_WILDCARD;

    const {
        processName,
        processState,
        states,
        // transitions,
        isCustomer,
        actionButtonProps,
        leaveReviewProps,
    } = processInfo;

    return new ConditionalResolver([processState, transactionRole])

        // .cond([states.SELECTED_VISIT_PLAN, CUSTOMER], () => {
        //     return {
        //         processName,
        //         processState,
        //         showDetailCardHeadings: true,
        //         showReviewAsSecondLink: true,
        //         showActionButtons: true,
        //         primaryButtonProps: leaveReviewProps,
        //     };
        // })

        // .cond([states.COMPLETED, _], () => {
        //     return {
        //         processName,
        //         processState,
        //         showDetailCardHeadings: true,
        //         showReviewAsFirstLink: true,
        //         showActionButtons: true,
        //         primaryButtonProps: leaveReviewProps,
        //     };
        // })
        // .cond([states.REVIEWED_BY_CUSTOMER, PROVIDER], () => {
        //     return {
        //         processName,
        //         processState,
        //         showDetailCardHeadings: true,
        //         showReviewAsSecondLink: true,
        //         showActionButtons: true,
        //         primaryButtonProps: leaveReviewProps,
        //     };
        // })
        // .cond([states.REVIEWED, _], () => {
        //     return { processName, processState, showDetailCardHeadings: true, showReviews: true };
        // })


        .default(() => {
            // Default values for other states
            return { processName, processState, showDetailCardHeadings: true };
        })
        .resolve();
};
