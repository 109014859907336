import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconProposal.module.css';
const SIZE_SMALL = 'small';

const IconProposal = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);


  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 13.25H9.5C10.1875 13.25 10.75 12.6875 10.75 12V2C10.75 1.3125 10.1875 0.75 9.5 0.75H2C1.3125 0.75 0.75 1.3125 0.75 2V12C0.75 12.6875 1.3125 13.25 2 13.25ZM2 23.25H9.5C10.1875 23.25 10.75 22.6875 10.75 22V17C10.75 16.3125 10.1875 15.75 9.5 15.75H2C1.3125 15.75 0.75 16.3125 0.75 17V22C0.75 22.6875 1.3125 23.25 2 23.25ZM14.5 23.25H22C22.6875 23.25 23.25 22.6875 23.25 22V12C23.25 11.3125 22.6875 10.75 22 10.75H14.5C13.8125 10.75 13.25 11.3125 13.25 12V22C13.25 22.6875 13.8125 23.25 14.5 23.25ZM13.25 2V7C13.25 7.6875 13.8125 8.25 14.5 8.25H22C22.6875 8.25 23.25 7.6875 23.25 7V2C23.25 1.3125 22.6875 0.75 22 0.75H14.5C13.8125 0.75 13.25 1.3125 13.25 2Z" fill="black" />
    </svg>
  );
};

const { string } = PropTypes;

IconProposal.defaultProps = {
  className: null,
  rootClassName: null,
};

IconProposal.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconProposal;
