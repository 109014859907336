export const transitions = {
    SELECT_VISIT_PLAN: 'transition/select-visit-plan',
    REQUEST_PAYMENT: 'transition/request-payment',
    CONFIRM_PAYMENT: 'transition/confirm-payment',
    EXPIRE_PAYMENT: 'transition/expire-payment',


};
export const states = {
    INITIAL: 'initial',
    PENDING_PAYMENT: 'pending-payment',
    PAYMENT_EXPIRED: 'payment-expired',
    PURCHASED: 'purchased',
};
export const graph = {
    id: 'reverse-purchase/release-1',
    initial: states.INITIAL,

    // States
    states: {
        [states.INITIAL]: {
            on: {
                [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
            },
        },
        [states.PENDING_PAYMENT]: {
            on: {
                [transitions.CONFIRM_PAYMENT]: states.PURCHASED,
                [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
            },
        },
        [states.PURCHASED]: {},

        [states.PAYMENT_EXPIRED]: {}



    },
};

export const isRelevantPastTransition = transition => {
    return [
        transitions.REQUEST_PAYMENT,
        transitions.CONFIRM_PAYMENT,
        transitions.EXPIRE_PAYMENT,
    ].includes(transition);
};
export const isCustomerReview = transition => {
    return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
    return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

export const isPrivileged = transition => {
    return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_INQUIRY].includes(
        transition
    );
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
    const txCompletedTransitions = [
        transitions.SELECT_VISIT_PLAN,
        transitions.REQUEST_PAYMENT,
        transitions.CONFIRM_PAYMENT,
        transitions.EXPIRE_PAYMENT,
    ];
    return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
    const txRefundedTransitions = [
        transitions.EXPIRE_PAYMENT,
        // transitions.AUTO_CANCEL,
        // transitions.AUTO_CANCEL_FROM_DISPUTED,
        // transitions.CANCEL_FROM_DISPUTED,
    ];
    return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PURCHASED];
