import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  propTypes,
  DATE_TYPE_DATE,
  DATE_TYPE_DATETIME,
  LINE_ITEM_NIGHT,
  LINE_ITEM_HOUR,
  LISTING_UNIT_TYPES,
  STOCK_MULTIPLE_ITEMS,
  INBOX_PAGE_CURRENT_TAB,
  INBOX_PAGE_PAST_TAB,
  AGENT,
  SELLER,
} from '../../util/types';
import { subtractTime } from '../../util/dates';
import {
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  resolveLatestProcessName,
  getProcess,
  isBookingProcess,
  CREDITPURCHASE_NAME,
} from '../../transactions/transaction';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import {
  H2,
  Avatar,
  NamedLink,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
  IconSpinner,
  TimeRange,
  UserDisplayName,
  LayoutSideNavigation,
  LayoutWrapperDashboardSideNav,
  Modal,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import { stateDataShape, getStateData } from './InboxPage.stateData';
import css from './InboxPage.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import UpdateCompensationForm from '../DasboardPage/UpdateCompensation';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { checkAcceptedTransition, checkIsCancelledTransication, formatPriceRange, getLastValues } from '../../util/data';
import ProfileIconCard from '../../components/SavedCardDetails/ProfileIconCard/ProfileIconCard';

const { Money } = sdkTypes;

// Check if the transaction line-items use booking-related units
const getUnitLineItem = lineItems => {
  const unitLineItem = lineItems?.find(
    item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
  );
  return unitLineItem;
};

// Booking data (start & end) are bit different depending on display times and
// if "end" refers to last day booked or the first exclusive day
const bookingData = (tx, lineItemUnitType, timeZone) => {
  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = tx.booking.attributes;
  const bookingStart = displayStart || start;
  const bookingEndRaw = displayEnd || end;

  // When unit type is night, we can assume booking end to be inclusive.
  const isNight = lineItemUnitType === LINE_ITEM_NIGHT;
  const isHour = lineItemUnitType === LINE_ITEM_HOUR;
  const bookingEnd =
    isNight || isHour ? bookingEndRaw : subtractTime(bookingEndRaw, 1, 'days', timeZone);

  return { bookingStart, bookingEnd };
};

const BookingTimeInfoMaybe = props => {
  const { transaction, ...rest } = props;
  const processName = resolveLatestProcessName(transaction?.attributes?.processName);
  const process = getProcess(processName);
  const isInquiry = process.getState(transaction) === process.states.INQUIRY;

  if (isInquiry) {
    return null;
  }

  const hasLineItems = transaction?.attributes?.lineItems?.length > 0;
  const unitLineItem = hasLineItems
    ? transaction.attributes?.lineItems?.find(
      item => LISTING_UNIT_TYPES.includes(item.code) && !item.reversal
    )
    : null;

  const lineItemUnitType = unitLineItem ? unitLineItem.code : null;
  const dateType = lineItemUnitType === LINE_ITEM_HOUR ? DATE_TYPE_DATETIME : DATE_TYPE_DATE;

  const timeZone = transaction?.listing?.attributes?.availabilityPlan?.timezone || 'Etc/UTC';
  const { bookingStart, bookingEnd } = bookingData(transaction, lineItemUnitType, timeZone);

  return (
    <TimeRange
      startDate={bookingStart}
      endDate={bookingEnd}
      dateType={dateType}
      timeZone={timeZone}
      {...rest}
    />
  );
};

BookingTimeInfoMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
};



export const InboxItem = props => {
  const {
    transactionRole,
    tx,
    intl,
    stateData,
    isBooking,
    stockType = STOCK_MULTIPLE_ITEMS,
    currentUser,
    isHistoryInbox = false
  } = props;
  const providerType = tx?.provider?.attributes?.profile?.publicData?.userType || {};
  const lastOfferByHeading = providerType == AGENT ? "Agent" : "Seller";
  const { orginalprice } = tx?.attributes?.protectedData || {}
  const inquiryDetails = tx?.attributes?.protectedData?.inquiryDetails || []
  const { publicData } = currentUser?.attributes?.profile || {};
  const { userType } = publicData || {};
  const { processName, processState, actionNeeded, isSaleNotification, isFinal } = stateData;
  const result = getLastValues(orginalprice, processName, inquiryDetails) || {};
  const { customer, provider, listing } = tx;
  const isCustomer = transactionRole === TX_TRANSITION_ACTOR_CUSTOMER;
  const lineItems = tx.attributes?.lineItems;
  const hasPricingData = lineItems.length > 0;
  const unitLineItem = getUnitLineItem(lineItems);
  const quantity = hasPricingData && !isBooking ? unitLineItem.quantity.toString() : null;
  const showStock = stockType === STOCK_MULTIPLE_ITEMS || (quantity && unitLineItem.quantity > 1);
  const { transitions } = tx?.attributes || {};
  const isAccpetedTransication = checkAcceptedTransition(transitions)
  const otherUser = isCustomer ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });
  const stateClasses = classNames(css.stateName, {
    [css.stateConcluded]: isFinal,
    [css.stateActionNeeded]: actionNeeded,
    [css.stateNoActionNeeded]: !actionNeeded,
  });

  return (
    <div className={css.item}>
      <div className={css.inboxCard}>
        <div className={css.itemAvatar}>
          <Avatar user={otherUser} />
          <span className={css.itemUsername}>{otherUserDisplayName}</span>
        </div>
        {userType != AGENT && processName != CREDITPURCHASE_NAME ?
          <div className={css.compentationSection}>
            <div className={css.profileCompentation}>
              <span className={css.leftHeading}>
                My Anchor
              </span>
              <span className={css.rigthDetail}>
                {result.buyerOrSeller.counterPriceFor == 'fixed-amount' ? `$${result.buyerOrSeller.inquiryPriceOrPercantage}` : `${result.buyerOrSeller.inquiryPriceOrPercantage}%`}
              </span>
            </div>
            <div className={css.profileCompentation}>
              <span className={css.leftHeading}>
                Agent Anchor
              </span>
              <span className={css.rigthDetail}>
                {result.agent.counterPriceFor == 'fixed-amount' ? `$${result.agent.inquiryPriceOrPercantage}` : `${result.agent.inquiryPriceOrPercantage}%`}
              </span>
            </div>
          </div> : null}


        {userType == AGENT && processName != CREDITPURCHASE_NAME ?
          <div className={css.compentationSection}>
            <div className={css.profileCompentation}>
              <span className={css.leftHeading}>
                My Anchor
              </span>
              <span className={css.rigthDetail}>
                {result.agent.counterPriceFor == 'fixed-amount' ? `$${result.agent.inquiryPriceOrPercantage}` : `${result.agent.inquiryPriceOrPercantage}%`}
              </span>
            </div>
            <div className={css.profileCompentation}>
              <span className={css.leftHeading}>
                {lastOfferByHeading} Anchor
              </span>
              <span className={css.rigthDetail}>
                {result.buyerOrSeller.counterPriceFor == 'fixed-amount' ? `$${result.buyerOrSeller.inquiryPriceOrPercantage}` : `${result.buyerOrSeller.inquiryPriceOrPercantage}%`}
              </span>
            </div>
          </div> : null}
      </div>
      <div className={css.buttonWrapper}>
        <NamedLink
          className={css.sendButton}
          name={isCustomer ? 'OrderDetailsPage' : 'SaleDetailsPage'}
          params={{ id: tx.id.uuid }}
        >
          {userType === AGENT || isHistoryInbox || isAccpetedTransication ? "View Details" : "Send offer"}
        </NamedLink>
        <div className={processState === "offer-accepted" ? css.accepted :
          processState === "inquiry" ? css.inquiry :
            processState === "canceled" ? css.canceled :
              null
        }>
          <FormattedMessage
            id={`InboxPage.${processName}.${processState}.status`}
            values={{ transactionRole }}
          />
          {processState == "offer-accepted" ?
            <span className={css.tickOffer}><ProfileIconCard type="offer" /></span>
            : null}
        </div>
      </div>
    </div>
  );
};

InboxItem.propTypes = {
  transactionRole: oneOf([TX_TRANSITION_ACTOR_CUSTOMER, TX_TRANSITION_ACTOR_PROVIDER]).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  stateData: stateDataShape.isRequired,
};

export const InboxPageComponent = props => {
  const config = useConfiguration();
  const {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    transactions,
    onUpdateProfile,
    updateInProgress
  } = props;
  function getPrevTxIdsFromTransactions(transactions) {
    return transactions.filter(transaction => {
      return transaction.attributes?.protectedData?.previoustxId;
    })
  }
  const prevTxIds = getPrevTxIdsFromTransactions(transactions);
  const ptxIdsArray = prevTxIds.map((tx) => tx.attributes.protectedData.previoustxId);
  const filterTransactions = transactions.filter(tx => !ptxIdsArray.includes(tx.id.uuid));




  const { tab } = params;
  const validTab = [INBOX_PAGE_CURRENT_TAB, INBOX_PAGE_PAST_TAB].includes(tab);
  if (!validTab) {
    return <NotFoundPage staticContext={props.staticContext} />;
  }

  const isOrders = tab === 'orders';
  const hasNoResults = !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError;
  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = ordersTitle;
  // isOrders ? ordersTitle : salesTitle;

  const pickType = lt => conf => conf.listingType === lt;
  const findListingTypeConfig = publicData => {
    const listingTypeConfigs = config.listing?.listingTypes;
    const { listingType } = publicData || {};
    const foundConfig = listingTypeConfigs?.find(pickType(listingType));
    return foundConfig;
  };
  const toTxItem = tx => {
    const transactionRole = (tx?.customer?.id?.uuid == currentUser?.id?.uuid) ? TX_TRANSITION_ACTOR_CUSTOMER : TX_TRANSITION_ACTOR_PROVIDER;

    let stateData = null;
    try {
      stateData = getStateData({ transaction: tx, transactionRole, intl });

    } catch (error) {
      // If stateData is missing, omit the transaction from InboxItem list.
    }

    const publicData = tx?.listing?.attributes?.publicData || {};
    const foundListingTypeConfig = findListingTypeConfig(publicData);
    const { transactionType, stockType } = foundListingTypeConfig || {};
    const process = tx?.attributes?.processName || transactionType?.transactionType;
    const transactionProcess = resolveLatestProcessName(process);
    const isBooking = isBookingProcess(transactionProcess);
    const isCancelledTransaction = checkIsCancelledTransication(tx)
    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        {isCancelledTransaction ? null :
          <InboxItem
            transactionRole={transactionRole}
            tx={tx}
            intl={intl}
            stateData={stateData}
            stockType={stockType}
            isBooking={isBooking}
            currentUser={currentUser}
          />
        }
      </li>
    ) : null;
  };
  const hasOrderOrSaleTransactions = (tx, isOrdersTab, user) => {
    return isOrdersTab
      ? user?.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user?.id?.uuid
      : user?.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user?.id?.uuid;
  };
  const hasTransactions = !fetchInProgress;
  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.ordersTabTitle" />
        </span>
      ),
      selected: tab == INBOX_PAGE_CURRENT_TAB,
      linkProps: {
        name: 'InboxPage',
        params: { tab: INBOX_PAGE_CURRENT_TAB },
      },
    },
  ];

  const [show, setShow] = React.useState(false);
  const sidebarRef = useRef(null);
  const handleClickOutside = event => {
    if (event.target !== sidebarRef.current) {
      setShow(false);
    }
  };

  const [activeTab, setActiveTab] = useState('current');
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);


  const { publicData } = currentUser?.attributes?.profile || {};
  const [openCompensationModal, setOpenCompensationModal] = useState(false);
  const handleCompModalOpen = () => {
    setOpenCompensationModal(true)
  };

  const handleSubmit = (values) => {
    const { compensationOffer, compPrice, compPercentage } = values || {};

    const profile = {
      publicData: {
        compensationOffer,
        compPrice: compPrice ? compPrice.amount / 100 : null,
        compPercentage
      },

    };
    onUpdateProfile(profile)
    setOpenCompensationModal(false);
  };

  const {
    compensationOffer,
    compPrice,
    compPercentage
  } = currentUser?.attributes?.profile?.publicData || {}

  const initialValues = {
    compensationOffer,
    compPrice: new Money(compPrice * 100, "USD"),
    compPercentage
  }
  const isProvider = currentUser?.attributes?.profile?.publicData?.userRole == "provider";
  const { price_range, minPrice, maxPrice } = publicData || {}
  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <TopbarContainer />

      <div className={css.editListingPageWrapper}>
        <div
          ref={sidebarRef}
          className={classNames(css.sidebar, show && isMobileLayout ? css.sidebarMobile : null)}
        >
          {isProvider ? <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} /> : <LayoutWrapperDashboardSideNav currentTab="InboxBasePage" />}
        </div>
        <div className={css.mainContent}>
          <div className={css.pageTitle}>
            <FormattedMessage id="InboxPage.title" />
          </div>
          <div className={css.profileContent}>
            {publicData?.userType !== AGENT &&
              <div>
                <div className={css.BuyTopSection}>
                  <span className={css.buyHeading}>Buy in this Location : </span><span className={css.buySubdetail}>{publicData?.location?.search}</span> <br />
                  <span className={css.buyHeading}>Price Range : </span>
                  <span className={css.buySubdetail}>{publicData?.userType == SELLER ?
                    formatPriceRange(price_range) : `$${minPrice} - $${maxPrice}`}
                  </span>
                </div>
                <div className={css.buttonTopWrapper}>
                  <div className={css.setButton} onClick={() => handleCompModalOpen(true)}>+ Set Anchor</div>
                  <div className={css.currentButton}>
                    <span className={css.currentCompemtation}>
                      Current Anchor
                    </span>
                    <span className={css.compentationPercentage}>
                      {publicData?.compensationOffer == "fixed-amount" ? "$" + publicData?.compPrice : publicData?.compPercentage + "%"}
                    </span>
                  </div>
                </div>
              </div>}
            {fetchOrdersOrSalesError ? (
              <p className={css.error}>
                <FormattedMessage id="InboxPage.fetchFailed" />
              </p>
            ) : null}
            <ul className={css.itemList}>
              {!fetchInProgress && currentUser?.id?.uuid ? (
                filterTransactions.map(toTxItem)
              ) : (
                <li className={css.listItemsLoading}>
                  <IconSpinner />
                </li>
              )}

            </ul>
            {hasTransactions && pagination && pagination.totalPages > 1 ? (
              <PaginationLinks
                className={css.pagination}
                pageName="InboxPage"
                pagePathParams={params}
                pagination={pagination}
              />
            ) : null}
          </div>
        </div>
        <div>
          <Modal
            isOpen={openCompensationModal}
            onClose={() => setOpenCompensationModal(false)}
            onManageDisableScrolling={() => { }}
          >
            <UpdateCompensationForm
              onSubmit={values => handleSubmit(values)}
              initialValues={initialValues}
              updateInProgress={updateInProgress}
            />
          </Modal>
        </div>
      </div>
      <FooterContainer />
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  const {
    updateInProgress,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    updateInProgress
  };
};
const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const InboxPage = compose(
  connect(mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(InboxPageComponent);

export default InboxPage;
