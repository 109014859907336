import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconEditNew.module.css';

const IconEditNew = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1 18H15C15.2652 18 15.5196 18.1054 15.7071 18.2929C15.8946 18.4804 16 18.7348 16 19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19C0 18.7348 0.105357 18.4804 0.292893 18.2929C0.48043 18.1054 0.734784 18 1 18ZM0 13L10 3L13 6L3 16H0V13ZM11 2L13 0L16 3L13.999 5.001L11 2Z" fill="white" />
    </svg>
  );
};

IconEditNew.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconEditNew.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconEditNew;
