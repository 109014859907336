import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPayment.module.css';
const SIZE_SMALL = 'small';

const IconPayment = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);


  return (
<svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.4 0H2.6C1.157 0 0.013 1.1125 0.013 2.5L0 17.5C0 18.8875 1.157 20 2.6 20H23.4C24.843 20 26 18.8875 26 17.5V2.5C26 1.1125 24.843 0 23.4 0ZM24 18H2V8H24V18ZM24 5H2V2H24V5Z" fill="black"/>
</svg>


    
  );
};

const { string } = PropTypes;

IconPayment.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPayment.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPayment;
