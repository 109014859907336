import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconProfile.module.css';
const SIZE_SMALL = 'small';

const IconProfile = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);


  return (
   

<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 15.9375C13.8875 15.9375 12.7999 15.6076 11.8749 14.9895C10.9499 14.3714 10.2289 13.4929 9.80318 12.4651C9.37744 11.4373 9.26604 10.3063 9.48309 9.21512C9.70013 8.12398 10.2359 7.1217 11.0225 6.33503C11.8092 5.54836 12.8115 5.01263 13.9026 4.79559C14.9938 4.57854 16.1248 4.68994 17.1526 5.11568C18.1804 5.54142 19.0589 6.26239 19.677 7.18742C20.2951 8.11245 20.625 9.19998 20.625 10.3125C20.625 11.8043 20.0324 13.2351 18.9775 14.29C17.9226 15.3449 16.4918 15.9375 15 15.9375ZM15 6.5625C14.2583 6.5625 13.5333 6.78244 12.9166 7.19449C12.2999 7.60655 11.8193 8.19222 11.5355 8.87744C11.2516 9.56266 11.1774 10.3167 11.3221 11.0441C11.4668 11.7715 11.8239 12.4397 12.3484 12.9642C12.8728 13.4886 13.541 13.8458 14.2684 13.9904C14.9958 14.1351 15.7498 14.0609 16.4351 13.7771C17.1203 13.4932 17.706 13.0126 18.118 12.3959C18.5301 11.7792 18.75 11.0542 18.75 10.3125C18.75 9.31794 18.3549 8.36411 17.6517 7.66085C16.9484 6.95759 15.9946 6.5625 15 6.5625Z" fill="black"/>
<path d="M15.0001 29.0623C12.9211 29.0609 10.8683 28.5986 8.98951 27.7086C7.11067 26.8187 5.45253 25.5232 4.13447 23.9154L3.64697 23.3154L4.13447 22.7248C5.45364 21.1192 7.11227 19.8259 8.99105 18.9379C10.8698 18.0499 12.922 17.5894 15.0001 17.5894C17.0781 17.5894 19.1304 18.0499 21.0091 18.9379C22.8879 19.8259 24.5466 21.1192 25.8657 22.7248L26.3532 23.3154L25.8657 23.9154C24.5477 25.5232 22.8895 26.8187 21.0107 27.7086C19.1319 28.5986 17.0791 29.0609 15.0001 29.0623ZM6.10322 23.3248C7.24344 24.5455 8.62241 25.5187 10.1545 26.1841C11.6866 26.8495 13.3391 27.1928 15.0095 27.1928C16.6798 27.1928 18.3323 26.8495 19.8644 26.1841C21.3965 25.5187 22.7755 24.5455 23.9157 23.3248C22.7755 22.1042 21.3965 21.1309 19.8644 20.4656C18.3323 19.8002 16.6798 19.4568 15.0095 19.4568C13.3391 19.4568 11.6866 19.8002 10.1545 20.4656C8.62241 21.1309 7.24344 22.1042 6.10322 23.3248Z" fill="black"/>
<path d="M15.0001 29.0625C11.8058 29.0646 8.70596 27.9792 6.21076 25.9848C3.71555 23.9904 1.97369 21.206 1.27186 18.0897C0.570031 14.9735 0.950054 11.7111 2.34938 8.83965C3.74871 5.96816 6.08396 3.65864 8.97077 2.2912C11.8576 0.923762 15.1239 0.579897 18.2322 1.3162C21.3405 2.05251 24.1055 3.8251 26.0721 6.34224C28.0387 8.85938 29.0898 11.9711 29.0523 15.1651C29.0148 18.3592 27.8909 21.4454 25.8657 23.9156C24.5477 25.5234 22.8895 26.8188 21.0107 27.7088C19.1319 28.5988 17.0791 29.0611 15.0001 29.0625ZM15.0001 2.81251C12.5897 2.81251 10.2333 3.52729 8.2291 4.86647C6.22487 6.20565 4.66277 8.10908 3.74033 10.3361C2.81789 12.563 2.57653 15.0135 3.04679 17.3777C3.51705 19.7418 4.67779 21.9134 6.38225 23.6179C8.0867 25.3223 10.2583 26.4831 12.6224 26.9533C14.9866 27.4236 17.4371 27.1822 19.6641 26.2598C21.891 25.3373 23.7945 23.7752 25.1336 21.771C26.4728 19.7668 27.1876 17.4105 27.1876 15C27.1876 11.7677 25.9036 8.66775 23.618 6.38215C21.3324 4.09655 18.2324 2.81251 15.0001 2.81251Z" fill="black"/>
<path d="M4.85645 23.3251C4.85645 23.3251 14.2971 33.872 23.9064 24.3751L25.1439 23.3251C25.1439 23.3251 17.1189 15.0001 8.97207 19.997L4.85645 23.3251Z" fill="black"/>
<path d="M15 15C17.5888 15 19.6875 12.9013 19.6875 10.3125C19.6875 7.72367 17.5888 5.625 15 5.625C12.4112 5.625 10.3125 7.72367 10.3125 10.3125C10.3125 12.9013 12.4112 15 15 15Z" fill="black"/>
</svg>


  );
};

const { string } = PropTypes;

IconProfile.defaultProps = {
  className: null,
  rootClassName: null,
};

IconProfile.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconProfile;
