export const transitions = {
    SELECT_VISIT_PLAN: 'transition/select-visit-plan',
    REQUEST_PAYMENT: 'transition/request-payment',
    CONFIRM_PAYMENT: 'transition/confirm-payment',
    EXPIRE_PAYMENT: 'transition/expire-payment',
    REQUEST_PAYMENT: 'transition/request-payment',
    // Not In USER
    // PURCHASED: 'transition/purchased',
    // REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
    // REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
    // REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
    // REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
    // EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
    // EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
    // EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};
export const states = {
    INITIAL: 'initial',
    SELECTED_VISIT_PLAN: 'selected-visit-plan',
    PENDING_PAYMENT: 'pending-payment',
    CANCELED: 'canceled',
    PAYMENT_EXPIRED: 'payment-expired',
    COMPLETED: 'completed',
    // No need these blow states
    // PURCHASED: 'purchased',
    // DELIVERED: 'delivered',
    // RECEIVED: 'received',
    // DISPUTED: 'disputed',
    // REVIEWED: 'reviewed',
    // REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
    // REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};
export const graph = {
    id: 'reverse-purchase/release-1',
    initial: states.INITIAL,

    // States
    states: {
        [states.INITIAL]: {
            on: {
                [transitions.SELECT_VISIT_PLAN]: states.SELECTED_VISIT_PLAN,
            },
        },
        [states.SELECTED_VISIT_PLAN]: {
            on: {
                [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
            },
        },
        [states.PENDING_PAYMENT]: {
            on: {
                [transitions.CONFIRM_PAYMENT]: states.COMPLETED,
                [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
            },
        },
        [states.PAYMENT_EXPIRED]: {},


        // [states.PURCHASED]: {
        //     on: {
        //         [transitions.COUNTER_BY_OFFER_ACCEPT_BY_CUSTOMER]: states.PENDING_PAYMENT,
        //         [transitions.COUNTER_BY_OFFER_DECLINE_BY_CUSTOMER]: states.CANCELED,
        //         [transitions.COUNTER_BY_OFFER_COUNTER_BY_CUSTOMER]: states.INQUIRY,
        //     }
        // },
        // [states.CANCELED]: {},
        // [states.REVIEWED_BY_CUSTOMER]: {
        //     on: {
        //         [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        //         [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
        //     },
        // },
        // [states.REVIEWED_BY_PROVIDER]: {
        //     on: {
        //         [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        //         [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
        //     },
        // },
        // [states.REVIEWED]: { type: 'final' },
    },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
    return [
        transitions.SELECT_VISIT_PLAN,
        transitions.REQUEST_PAYMENT,
        transitions.CONFIRM_PAYMENT,
        transitions.EXPIRE_PAYMENT,
        // transitions.REVIEW_1_BY_CUSTOMER,
        // transitions.REVIEW_1_BY_PROVIDER,
        // transitions.REVIEW_2_BY_CUSTOMER,
        // transitions.REVIEW_2_BY_PROVIDER, 
        // transitions.PURCHASED,
        // transitions.AUTO_CANCEL,
        // transitions.MARK_DELIVERED,
        // transitions.OPERATOR_MARK_DELIVERED,
        // transitions.DISPUTE,
        // transitions.OPERATOR_DISPUTE,
        // transitions.AUTO_COMPLETE,
        // transitions.AUTO_CANCEL_FROM_DISPUTED,
        // transitions.CANCEL_FROM_DISPUTED,
        // transitions.ACCEPT_BY_PROVIDER,
        // transitions.DECLINE_BY_PROVIDER,
        // transitions.COUNTER_BY_PROVIDER,
        // transitions.REQUEST_PAYMENT_AFTER_INQUIRY,
        // transitions.COUNTER_BY_OFFER_ACCEPT_BY_CUSTOMER,
        // transitions.COUNTER_BY_OFFER_DECLINE_BY_CUSTOMER,
        // transitions.COUNTER_BY_OFFER_COUNTER_BY_CUSTOMER,
        // transitions.CREATE_VISIT_PLAN_BYAGENT,
        // transitions.PURCHASE_VISIT_PLAN,
    ].includes(transition);
};
export const isCustomerReview = transition => {
    return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
    return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
    return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_INQUIRY].includes(
        transition
    );
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
    const txCompletedTransitions = [
        transitions.SELECT_VISIT_PLAN,
        transitions.REQUEST_PAYMENT,
        transitions.CONFIRM_PAYMENT,
        transitions.EXPIRE_PAYMENT,
        // transitions.REVIEW_1_BY_CUSTOMER,
        // transitions.REVIEW_1_BY_PROVIDER,
        // transitions.REVIEW_2_BY_CUSTOMER,
        // transitions.REVIEW_2_BY_PROVIDER,
        // transitions.EXPIRE_REVIEW_PERIOD,
        // transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        // transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    ];
    return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
    const txRefundedTransitions = [
        transitions.EXPIRE_PAYMENT,
        // transitions.AUTO_CANCEL,
        // transitions.AUTO_CANCEL_FROM_DISPUTED,
        // transitions.CANCEL_FROM_DISPUTED,
    ];
    return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PURCHASED];
