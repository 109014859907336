import { types as sdkTypes,createImageVariantConfig } from '../../util/sdkLoader';
import pick from 'lodash/pick';
import { storableError } from '../../util/errors';
import { getPurchaseCredits, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';

import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  parse,
} from '../../util/urlHelpers';

const { UUID } = sdkTypes;

const RESULT_PAGE_SIZE = 50;
const listingId = process.env.REACT_APP_CREDIT_LISTING_ID


// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  pagination:null,
};

const purchaseCreditsPageReducer = (state = initialState, action = {}) => {
    const { type, payload } = action;
    switch (type) {
        case SET_INITIAL_VALUES:
            return { ...initialState, ...payload };

            case SHOW_LISTING_REQUEST:
              return { ...state, id: payload.id, showListingError: null };
            case SHOW_LISTING_ERROR:
              return { ...state, showListingError: payload };

        case FETCH_LINE_ITEMS_REQUEST:
            return { 
                ...state, 
                fetchLineItemsInProgress: true, 
                fetchLineItemsError: null 
            };
        case FETCH_LINE_ITEMS_SUCCESS:
            return { 
                ...state, 
                fetchLineItemsInProgress: false, 
                lineItems: payload 
            };
        case FETCH_LINE_ITEMS_ERROR:
            return { 
                ...state, 
                fetchLineItemsInProgress: false, 
                fetchLineItemsError: payload 
            };

        default:
            return state;
  }
};

export default purchaseCreditsPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const showListing = (config,isOwn = false) => (dispatch, getState, sdk) => {
const {
  aspectWidth = 1,
  aspectHeight = 1,
  variantPrefix = 'listing-card',
} = config.layout.listingImage;
const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  }; 

  const show =  isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);
  return show
  .then(data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
});

};

export const loadData = (params, search, config) => (dispatch) => {
  
  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing( config, true));
  }
  const queryParams = parse(search);
  const { page = 1 } = queryParams;

  return Promise.all([
    dispatch(showListing(config)), 
  ])
  .then(response => {
    return response;
  });
};